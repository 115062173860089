import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Container } from "react-bootstrap";

import './PropertyNowArchive.scss';

const PropertyNowArchiveContent = () => {

  return (
    <div class="row">
        <div class="list-articles-col col-sm-4 col-12">
            <div class="row">
                <div class="col">
                    <a href="http://view.henriconow.henrico.com/?qs=2880effa9351fc5b38a63b91b6e7c2d747db8335f40280907bf2564868dc3fd0afcefad485fd8bcd5a126a80c8dcb4d154195847c17985e2b8587dcde68f9528a14ee0cd1cb4f9fecc845549a6dae544" target="_blank;">
                        <img class="henrico-now-article-grid-image" alt="" src="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/industrial-and-manufacturing/HEN24007_Former+Richmond+Dragway+Site.png"/>
                    </a>
                </div>
            </div>
            <div class="mt-10 row">
                <div class="col">
                    <a class="henrico-archive-title--black" href="http://view.henriconow.henrico.com/?qs=2880effa9351fc5b38a63b91b6e7c2d747db8335f40280907bf2564868dc3fd0afcefad485fd8bcd5a126a80c8dcb4d154195847c17985e2b8587dcde68f9528a14ee0cd1cb4f9fecc845549a6dae544" target="_blank;">                  	
                    Former Richmond Dragway Site
                    </a>
                </div>
            </div>
            <div class="henrico-archive-date row">
                <div class="col">
                    <p>2024-08-15</p>
                </div>
            </div>
        </div>
        <div class="list-articles-col col-sm-4 col-12">
            <div class="row">
                <div class="col">
                    <a href="http://view.henriconow.henrico.com/?qs=7a52077122a33b402dd18c3e3029923dfe6eae915ca5f6676ea74c718de69a4900e1b5c8cbdc4d9b2b019263e7cd74f6f379f180b7138ceb05610b426d3f9bb136dea280dcf5bda7299b4e61d248df8f" target="_blank;">
                        <img class="henrico-now-article-grid-image" alt="" src="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/industrial-and-manufacturing/HEN24007_Premium+restaurant+venue.png"/>
                    </a>
                </div>
            </div>
            <div class="mt-10 row">
                <div class="col">
                    <a class="henrico-archive-title--black" href="http://view.henriconow.henrico.com/?qs=7a52077122a33b402dd18c3e3029923dfe6eae915ca5f6676ea74c718de69a4900e1b5c8cbdc4d9b2b019263e7cd74f6f379f180b7138ceb05610b426d3f9bb136dea280dcf5bda7299b4e61d248df8f" target="_blank;">                  	
                    Restaurant Space at Regency
                    </a>
                </div>
            </div>
            <div class="henrico-archive-date row">
                <div class="col">
                    <p>2024-07-11</p>
                </div>
            </div>
        </div>         
        <div class="list-articles-col col-sm-4 col-12">
            <div class="row">
                <div class="col">
                    <a href="http://view.henriconow.henrico.com/?qs=7a52077122a33b402dd18c3e3029923dfe6eae915ca5f6676ea74c718de69a4900e1b5c8cbdc4d9b2b019263e7cd74f6f379f180b7138ceb05610b426d3f9bb136dea280dcf5bda7299b4e61d248df8f" target="_blank;">
                        <img class="henrico-now-article-grid-image" alt="" src="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/industrial-and-manufacturing/HEN24007_Sauer+Industrial+Center.png"/>
                    </a>
                </div>
            </div>
            <div class="mt-10 row">
                <div class="col">
                    <a class="henrico-archive-title--black" href="http://view.henriconow.henrico.com/?qs=7a52077122a33b402dd18c3e3029923dfe6eae915ca5f6676ea74c718de69a4900e1b5c8cbdc4d9b2b019263e7cd74f6f379f180b7138ceb05610b426d3f9bb136dea280dcf5bda7299b4e61d248df8f" target="_blank;">                  	
                    Several Class A Modern Industrial Facilities Available for Lease Near I-64 and I-95
                    </a>
                </div>
            </div>
            <div class="henrico-archive-date row">
                <div class="col">
                    <p>2024-06-13</p>
                </div>
            </div>
        </div>         
        <div class="list-articles-col col-sm-4 col-12">
            <div class="row">
                <div class="col">
                    <a href="http://view.henriconow.henrico.com/?qs=37ce726c48fb3520ffc61fe47ce044f990295e0a10b29537e238417c932ca3aad7f94eaa3a0416f5c59be0dc440ad79c9323b231e47d5765993fd681c514ee1e7617e488637e76d3b5cfe7ccc79c1adf" target="_blank;">
                        <img class="henrico-now-article-grid-image" alt="" src="https://henrico-eda-site-assets.s3.amazonaws.com/featured-properties/industrial-and-manufacturing/HEN24007_Highwinds+One.png"/>
                    </a>
                </div>
            </div>
            <div class="mt-10 row">
                <div class="col">
                    <a class="henrico-archive-title--black" href="http://view.henriconow.henrico.com/?qs=37ce726c48fb3520ffc61fe47ce044f990295e0a10b29537e238417c932ca3aad7f94eaa3a0416f5c59be0dc440ad79c9323b231e47d5765993fd681c514ee1e7617e488637e76d3b5cfe7ccc79c1adf" target="_blank;">                  	
                        Five stunning, newly renovated Class A office spaces in thriving Innsbrook
                    </a>
                </div>
            </div>
            <div class="henrico-archive-date row">
                <div class="col">
                    <p>2024-04-17</p>
                </div>
            </div>
        </div>  
        <div class="list-articles-col col-sm-4 col-12">
            <div class="row">
                <div class="col">
                    <a href="http://view.henriconow.henrico.com/?qs=12eaaf19d366178146f459b80751496ca28f9a2b67be2bc4d2adc72977459c7a96b76aea0bde5a23041bdf4ca1b678c470f28f8b452cdbfc6ee8b127f732a3d89aa8d531fd3d4cae6c890b4b40e0c2d3" target="_blank;">
                        <img class="henrico-now-article-grid-image" alt="" src="https://henrico-eda-site-assets.s3.amazonaws.com/henrico-now/2023/HEN23008_Henrico+Industrial+Properties.png"/>
                    </a>
                </div>
            </div>
            <div class="mt-10 row">
                <div class="col">
                    <a class="henrico-archive-title--black" href="http://view.henriconow.henrico.com/?qs=12eaaf19d366178146f459b80751496ca28f9a2b67be2bc4d2adc72977459c7a96b76aea0bde5a23041bdf4ca1b678c470f28f8b452cdbfc6ee8b127f732a3d89aa8d531fd3d4cae6c890b4b40e0c2d3" target="_blank;">                  	
                        Henrico Industrial Properties
                    </a>
                </div>
            </div>
            <div class="henrico-archive-date row">
                <div class="col">
                    <p>2024-01-31</p>
                </div>
            </div>
        </div>       
    </div>
  );
};

export default PropertyNowArchiveContent;