import React from "react";
import { Container } from "react-bootstrap";
import AboutUsHero from "../AboutUs/AboutUsHero";
import SiteSelectorsNav from "../shared/Navs/AboutUsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import { RemoteContent } from '../shared/Cms';

const Careers = (data) => {
  const { domain, section, page } = {
    domain: 'henrico',
    section: 'about-us',
    page: 'Careers'
  };

  return(
    <div>
      <AboutUsHero/>
        <Container>
          <SiteSelectorsNav/>
            <PageTitle title={
              <RemoteContent
                domain={domain}
                section={section}
                page={page}
                field="title"
              />
            } />
            <p>If you love helping others succeed and are passionate about a career that contributes to the vitality of your community, look no further. Whether we’re assisting our more than 25,000 small businesses, seven Fortune 1000 companies, or more than 330,000 residents, the Henrico EDA makes it our mission to promote a vibrant business climate and outstanding quality of life. Be a part of our team and discover the benefits of working for a bustling community in the Top State for Business with one of the best average commute times in the nation (22 minutes!). From a <a href="https://www.greencityva.com/" target="_blank;">visionary eco-district</a> to a <a href="https://www.henrico.com/news/press-releases-and-announcements/2020-12-17/de-cix" target="_blank;">booming data infrastructure</a>, there are a wide variety of extraordinary projects that you can help advance in Henrico right now – with a lot more in store. Henrico Economic Development Authority is an Equal Opportunity Employer. Come join us in growing Henrico’s economy for all.  </p>
   
            {/* <p><strong>BUSINESS MANAGER</strong></p>
            <p><strong>Economic Development Authority, Henrico, Virginia</strong></p>
            <p><strong>Competencies</strong></p>
            <ul>
              <li>Self-starter with ability to function well both independently and within a small team</li>
              <li>Excellent record-keeping, organizational skills, and attention to detail</li>
              <li>Ability to:
                <li>Effectively support business programs and initiatives</li>
                <li>Ability to create and deliver effective presentations showcasing Henrico's unique value proposition</li>
                <li>Communicate effectively, in both oral and written form</li>
                <li>Establish and maintain effective working relationships with coworkers, County agencies, external partners, and the public</li>
                <li>Navigate complex situations with multiple stakeholders</li>
                <li>Multitask and effectively manage competing priorities</li>
              </li>
            </ul>
            <p><strong>Must Have</strong></p>
            <ul>
              <li>Bachelor's degree in a related field</li>
              <li>3-5 years of relevant work experience</li>
              <li>Thorough understanding of economic development and marketing practices</li>
              <li>Working knowledge of commercial and industrial real estate and real estate development process</li>
              <li>Project management experience</li>
            </ul>
            <p><a href="https://henrico-eda-site-assets.s3.amazonaws.com/about-us/business+manager+ad+0624+final.png" target="_blank;">Click here to view full job listing.</a></p>
            <br/><br/>
            <p><strong>BUSINESS SPECIALIST</strong></p>
            <p><strong>Economic Development Authority, Henrico, Virginia</strong></p>
            <p><strong>Competencies</strong></p>
            <ul>
              <li>Self-starter with ability to function well both independently and within a small team</li>
              <li>Excellent record-keeping, organizational skills, and attention to detail</li>
              <li>Ability to:
                <li>Effectively support business programs and initiatives</li>
                <li>Communicate effectively, in both oral and written form</li>
                <li>Establish and maintain effective working relationships with coworkers, County agencies, external partners, and the public</li>
                <li>Navigate complex situations with multiple stakeholders</li>
                <li>Multitask and effectively manage competing priorities</li>
              </li>
            </ul>
            <p><strong>Must Have</strong></p>
            <ul>
              <li>High school graduate with 5+ years of relevant work experience</li>
              <li>Working knowledge of Microsoft suite applications</li>
              <li>Sound knowledge of general office operations and proficiency in related technology</li>
              <li>Knowledge of Salesforce and Oracle software preferred</li>
            </ul>
            <p><a href="https://henrico-eda-site-assets.s3.amazonaws.com/about-us/business+specialist+post+.png" target="_blank;">Click here to view full job listing.</a></p> */}
                <RemoteContent
                  domain={domain}
                  section={section}
                  page={page}
                  field="s1"
                />
                    </Container>
                      </div>
  )
}

export default Careers;
