import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router';
import "./HomeFacts.scss";
import { Row, Col, Container, Image } from "react-bootstrap";
import axios from 'axios';
import { sortEvents } from '../shared/Utilities/Sort';
import { HashLink as Link } from 'react-router-hash-link';
import { FactGrid } from '../WhyHenrico/FactGrid';
import { remoteData } from '../shared/Cms';
import '../shared/DataCard/HomeDataCard.scss';
import Quote from "../shared/Quote/Quote";

const HomeFacts = (props) => {
  const history = useHistory();
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const hasNoEvents = () => {
    return ((upcomingEvents === undefined || upcomingEvents.length === 0))
  };

  const hasUpcomingEvents = (date) => {
    const eventDate = new Date(date);
    const todaysDate = new Date();
    todaysDate.setDate(todaysDate.getDate() - 1);
    return todaysDate <= eventDate;
  };


  

    //upcoming events
    useEffect(() => {
      remoteData({domain: 'henrico', section: 'upcoming-events'})
        .then(data => {
          const events = sortEvents(data);
          setUpcomingEvents(events);
          setLoading(false);
        });
    }, []);

    const [featuredPressRelease, setFeaturedPressRelease] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_NEWS_URL}/press-releases-and-announcements`)
            .then(res => {
                setLoading(true);
                const pressReleasesAndAnnouncements = res.data.pressReleasesAndAnnouncements;
                const featuredPressRelease = pressReleasesAndAnnouncements.sort(function (a, b) {
                    return (b.date < a.date) ? -1 : ((b.date > a.date) ? 1 : 0);
                });
                setFeaturedPressRelease(featuredPressRelease[0]);
                setLoading(false);
            })
    }, []);

    const handleClick = (e, articleSlug, postDate, articleId, externalLink) => {
        e.preventDefault();

        const url = `/news/press-releases-and-announcements/${postDate}/${articleSlug}`;
        externalLink ? window.open(articleSlug) : (history.push(url, {
            articleId
        }))
    }

    const quoteData = {
        copy:
          "Virginia has been a home to the internet since the tech industry’s earliest days. And thanks to Henrico County’s robust infrastructure and attractive business climate for data center development, we could not be more thrilled to locate our next data center here. When considering...locations, we not only look for clean and renewable energy solutions, but great partnerships within the local community, a strong pool of local talent, excellent access to fiber, and a robust electric grid. Henrico and the Commonwealth of Virginia has it all.",
        signature: "- Rachel Peterson, Vice President, Infrastructure, META."
      };

    if(loading) {
      return <>Loading...</>;
    }
    return (
        <Container>
                <Row className="no-margin">
                    <Col>
                        <div className="henrico-text" ><h6>HENRICO ECONOMIC DEVELOPMENT AUTHORITY </h6></div>
                    </Col>
                </Row>
                <Row className="no-margin">
                    <Col>
                    <div className="featured-video" ><iframe width="860" height="484" src="https://www.youtube.com/embed/s4jeiDnMmG8" title="Henrico Year in Review FY2024 2025" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
                    <div className="featured-video-text" ><h4>Henrico Year in Review FY2024</h4></div>
                    {/* <div className="featured-video-text2" ><p>Top industry powerhouses including Amazon, Coca-Cola, Tucker Door & Trim, and RopeBlock all celebrated grand openings with Henrico this March.</p></div> */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col xs={12} lg={8}>
                                <Row className="no-margin">
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-center">
                                        <h4 className="featured-news-title-mobile">Featured News</h4>
                                        <a onClick={(e) => handleClick(e, featuredPressRelease.slug, featuredPressRelease.date, featuredPressRelease._id, featuredPressRelease.external_link )}
                                            href={featuredPressRelease.slug}><Image src={`${featuredPressRelease.image}`} alt={`${featuredPressRelease.title_archive}`} className="featured-news-image"/></a>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6} className="featured-news-border">
                                        <div>
                                            <h4 className="featured-news-title-desktop">Featured News</h4>
                                            <div className="order-sm-3 breaking-section">
                                                <span className="breaking">BREAKING: </span>
                                                <span>
                                                    <a 
                                                        className="breaking-news-link-copy"
                                                        // onClick={(e) => handleClick(e, featuredPressRelease.slug, featuredPressRelease.date, featuredPressRelease._id, featuredPressRelease.external_link)}
                                                        // href={featuredPressRelease.slug}
                                                        href="https://www.henrico.com/news/press-releases-and-announcements/2024-9-10/hgbg"
                                                    >
                                                        {featuredPressRelease.title_archive}
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={4} className="col-upcoming-events">
                                <Row className="upcoming-events-row">
                                    <Col xs={12}>
                                        <div>
                                            <h4 className="upcoming-events-title">Upcoming Events <br /></h4>
                                        </div>
                                    </Col>
                                    <Col xs={12} className="upcoming-events-text">
                                        {
                                          hasNoEvents() || (upcomingEvents && !upcomingEvents.some(event => hasUpcomingEvents(event.date)))
                                            ? <p>Please check back later for new events.</p>
                                            : upcomingEvents && upcomingEvents.map((value, index) => {
                                             // const date = value.date; // Assuming value.date is a string in the format 'YYYY-MM-DD'
                                             // const [year, month, day] = date.split('-');
                                             // const formattedDate = `${parseInt(month, 10)}/${parseInt(day, 10)}/${year}`; various methods for displaying date formats if we dont want YYYY-mm-dd

                                              //const dateObj = new Date(value.date);
                                              //const month = dateObj.getMonth() + 1; // getMonth() returns 0-11
                                              //const day = dateObj.getDate();
                                              //const year = dateObj.getFullYear();
                                              //const formattedDate = `${month}/${day}/${year}`;
                                              return hasUpcomingEvents(value.date)
                                               // ? <ul><li key={index}><Link to={`${value.archive_link}`}>{formattedDate} {value.archive_title}</Link></li></ul>
                                                ? <ul><li key={index}><Link to={`${value.archive_link}`}>{value.date} {value.archive_title}</Link></li></ul>

                                                : ""
                                        })
                                        }

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Quote copy={quoteData.copy} signature={quoteData.signature} />
                </Row>
                <Row className="card-row-home-cards cards-home">
                  <FactGrid numFacts={6} page="fast-facts" isHome={true} />
                </Row>
        </Container>
    )
};

export default HomeFacts;
