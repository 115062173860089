import WhiteOakHero from "./WhiteOakHero";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import ResourcesAndDownloads from "../shared/FindAProperty/ResourcesAndDownloads";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import './WhiteOakLocation.scss'
import './WhiteOakInfrastructure.scss'
import WhiteOakNav from "../shared/Navs/WhiteOakNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import './WhiteOakBusinessClimate.scss';

const paragraphStyles = {
    fontWeight: 600,
};

const pageTitle = "Business Climate";

// const quoteContent = "Our U.S. expansion project is an exciting opportunity for future growth. We explored U.S. locations and selected Virginia and Henrico among several that were considered. Not only is this location in close proximity to most of our U.S. customers – it provides us with a foundation for future strategic growth and expansion."
// const quoteSignature = "Lionel Genix, General Manager, Air Liquide Medical Systems Polykon Manufacturing is a joint venture between two subsidiaries of Air Liquide – SEPPIC Inc. and Shülke Inc."

const WhiteOakBusinessClimate = ()=>{
    return (
        <div>
            <WhiteOakHero />
            <Container>
                <WhiteOakNav/>
                <PageTitle title={pageTitle} />
                <Row>
                    <Col>
                        <p>Reap the benefits of state and local government dedicated to cultivating a dynamic business climate. White Oak Technology Park is located in Henrico, Virginia – one of the best-run localities in the nation. Henrico’s commitment to its business community and residents is reflected in low taxes, exceptional public services, and strategic deployment of public assets. As a result, Henrico is home to seven Fortune 1000 company headquarters and more than 25,000 individual businesses, and ranks second in Virginia for total employment. </p><p><span style={paragraphStyles}></span>White Oak Technology Park benefits from the Commonwealth of Virginia’s pro-business statutes and economic development support. Virginia maintains a robust network of transportation and shipping infrastructure, strong incentive packages for qualifying projects, and a deep labor pool – including the highest concentration of tech workers in the nation. As a right-to-work state, Virginia has one of the lowest private sector unionization rates in the U.S., at 2.5%.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col xs={12}>
                                <p className="paragraph-header--blue">Existing Industry</p>
                                <ul className="white-oak-location-list">
                                <li>Seven Fortune 1000 headquarters and more than 25,000 businesses</li>
                                <li>More than 55M sq. ft. of commercial real estate, including 33M sq. ft. of industrial space</li>
                                <li>Businesses in White Oak Technology Park include QTS Data Center, Lumber Liquidators, META, H.P., and Polykon – a joint venture of Seppic-Shulke</li>
                            </ul>
                          </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <p className="paragraph-header--blue">Skilled Workforce</p>
                                <ul className="white-oak-location-list">
                                <li>44% of Henrico residents have a bachelor’s degree or higher</li>
                                <li>Metro labor force of more than 660,000 workers</li>
                                <li><a href="https://henricoschools.us/specialty-centers/" target="_blank" rel="noopener noreferrer">K-12 workforce programs</a> focusing on S.T.E.M. education</li>
                                <li>90,000+ graduates per year from nearby colleges and universities, including Virginia Commonwealth University and the University of Richmond</li>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <p className="paragraph-header--blue">Competitive Taxes/Low Operating Costs</p>
                                <ul className="white-oak-location-list">
                                <li>85¢ per $100 real estate tax, including six rate decreases since 1978</li>
                                <li>40¢ per $100 assessed value on data center equipment and an aggressive depreciation schedule</li>
                                <li>Electricity costs 30% below the national average, provided by Dominion Energy  </li>
                                <li>30¢ per $100 assessed value on manufacturing machinery & tools, with the lowest effective rate in Central Virginia – 24¢ for Year 1</li>
                                <li><a href="/site-selectors/taxes" rel="noopener noreferrer" className="link-light">More tax information can be found here</a></li>
                            </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <p className="paragraph-header--blue">Business Incentives</p>
                                <ul className="white-oak-location-list">
                                <li><a href="/site-selectors/incentives" target="_blank" rel="noopener noreferrer">Discretionary incentive opportunities</a> for qualifying projects generating capital investment and new jobs</li>
                                <li>Fast-track planning and approval process for qualifying projects</li>
                                <li>Access to <a href="https://www.vedp.org/incentives" target="_blank" rel="noopener noreferrer">Commonwealth of Virginia Incentives</a></li>
                                <li>Customized workforce recruitment and training services</li>
                                <li>White Oak Technology Park is owned and managed by the Henrico EDA</li>
                            </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </Container>
                <ResourcesAndDownloads />
                <Container>
                <NewsInsights/>
            </Container>
        </div>
    );
}

export default WhiteOakBusinessClimate;
