import React, {  } from "react";
import "./HomeRotatingBanner.scss";
import { Row, Col, Container } from "react-bootstrap";

const HomeRotatingBanner = () => {
    return (
        <Container fluid className="rotating-banner-row">
            <Row noGutters={true}>
                <Col>
                    <div className="companies-banner">
                        <h4 className="companies-banner-title">WHERE THE BEST COMPANIES LAND</h4>
                        <p className="companies-banner-description">More than 25,000 businesses and seven Fortune 1000 company headquarters call Henrico their home.</p>
                    </div>
                </Col>
            </Row>
            <Row noGutters={true}>
                <Col>
                    <div className="slideshow">
                        <div className="images">
                        </div>
                    </div>
                    <div className="slideshow-bottom-spacer"></div>
                </Col>
            </Row>
        </Container>
    );
}

export default HomeRotatingBanner;